import ky from 'ky';

import FilterDates from '@/types/FilterDates';
import UpdateUserModel from '@/types/UpdateUserModel';
import AddCustomerUserModel from '@/types/AddCustomerUserModel';
import CreateTaskSiteModel from '@/types/CreateTaskSiteModel';
import CreateContractModel from '@/types/CreateContractModel';
import CreateOrderModel from '@/types/CreateOrderModel';
import { formatISO } from 'date-fns';

export const apiBaseUrl = `${location.origin}/api/`;

const api = ky.create({
    prefixUrl: apiBaseUrl,
    throwHttpErrors: false,
    timeout: 30_000,
    retry: 0,
});

/**
 * Backend api client
 *
 * Generally a pretty simple layer that mostly passes through the response. Error handling etc. is
 * done in the actions
 */
export default class Api {
    private api: typeof ky = api;

    public async fetchOrders(customerId: string) {
        const r = await this.api.get(`customers/${customerId}/orders`);

        return r;
    }

    public async fetchContracts(customerId: string) {
        const r = await this.api.get(`customers/${customerId}/contracts`);

        return r;
    }

    public async fetchTaskSiteContracts(customerId: string, taskSiteId: string) {
        const r = await this.api.get(`customers/${customerId}/tasksites/${taskSiteId}/contracts`);

        return r;
    }

    public async fetchCustomer(customerId: string) {
        const r = await this.api.get(`customers/${customerId}`);

        return r;
    }

    public async fetchDocuments(customerId: string, { from, to }: FilterDates) {
        let params = {};

        if (from && to) {
            params = {
                from: formatISO(from, { representation: 'date' }),
                to: formatISO(to, { representation: 'date' }),
            };
        }

        const r = await this.api.get(`customers/${customerId}/documents`, { searchParams: params });

        return r;
    }

    public async fetchTaskSiteDocuments(
        customerId: string,
        taskSiteId: string,
        { from, to }: FilterDates
    ) {
        let params = {};

        if (from && to) {
            params = {
                from: formatISO(from, { representation: 'date' }),
                to: formatISO(to, { representation: 'date' }),
            };
        }

        const r = await this.api.get(`customers/${customerId}/tasksites/${taskSiteId}/documents`, {
            searchParams: params,
        });

        return r;
    }

    public async updateActiveCustomerId(customerId: string) {
        const r = await this.api.put(`users/current/customer/${customerId}`);

        return r;
    }

    public async fetchCurrentUser() {
        const r = await this.api.get(`users/current`);

        return r;
    }

    public async fetchTaskSites(customerId: string) {
        const r = await this.api.get(`customers/${customerId}/tasksites`);

        return r;
    }

    public async fetchCategories() {
        const r = await this.api.get(`categories`);

        return r;
    }

    public async fetchContractTemplates() {
        const r = await this.api.get(`contracttemplates`);

        return r;
    }

    public async fetchEquipment() {
        const r = await this.api.get(`equipment`);

        return r;
    }

    public async createTaskSite(customerId: string, model: CreateTaskSiteModel) {
        const r = await this.api.post(`customers/${customerId}/tasksites`, {
            json: model,
        });

        return r;
    }

    public async createContract(
        customerId: string,
        taskSiteId: string,
        model: CreateContractModel
    ) {
        const r = await this.api.post(`customers/${customerId}/tasksites/${taskSiteId}/contracts`, {
            json: model,
        });

        return r;
    }

    public async createOrder(customerId: string, taskSiteId: string, model: CreateOrderModel) {
        const r = await this.api.post(`customers/${customerId}/tasksites/${taskSiteId}/orders`, {
            json: model,
        });

        return r;
    }

    public async deleteOrder(customerId: string, taskSiteId: string, orderId: string) {
        const r = await this.api.delete(
            `customers/${customerId}/tasksites/${taskSiteId}/orders/${orderId}`
        );

        return r;
    }

    public async sendOrderMessage(
        customerId: string,
        contractId: string,
        orderId: string,
        message: string,
        replyTo: string
    ) {
        const r = await this.api.post(`orders/messages`, {
            json: {
                customerId,
                contractId,
                orderId,
                message,
                replyTo,
            },
        });

        return r;
    }

    public async fetchUsers(customerId: string) {
        const r = await this.api.get(`customers/${customerId}/users`);

        return r;
    }

    public async updateUser(customerId: string, model: UpdateUserModel) {
        const r = await this.api.put(`customers/${customerId}/users/${model.userId}`, {
            json: model,
        });

        return r;
    }

    public async addCustomerUser(
        customerId: string,
        model: AddCustomerUserModel,
        fullPermissions: boolean = false
    ) {
        const r = await this.api.post(`customers/${customerId}/users`, {
            json: model,
            searchParams: { fullPermissions: fullPermissions ? 'true' : 'false' },
        });

        return r;
    }

    public async removeCustomerUser(customerId: string, email: string) {
        return await this.api.delete(`customers/${customerId}/users/${email}`);
    }

    public async fetchHolidays() {
        return await this.api.get(`holidays`);
    }
}
