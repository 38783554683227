











import { computed, defineComponent } from '@vue/composition-api';

interface Props {
    theme: string;

    mute: boolean;
}

export default defineComponent<Props>({
    props: {
        theme: {
            default: 'ci',
        },
        mute: {
            default: true,
        },
    },
    setup(props) {
        const classes = computed(() => {
            return {
                'loader-ellipsis': true,
                'loader-ellipsis--mute': props.mute,
                'loader-ellipsis--ci': props.theme === 'ci',
                'loader-ellipsis--dark': props.theme === 'dark',
                'loader-ellipsis--light': props.theme === 'light',
                'loader-ellipsis--primary': props.theme === 'primary',
            };
        });

        return {
            classes,
        };
    },
});
