import Vue from 'vue';
import Vuex from 'vuex';

import { getActiveCustomerId, getCustomerIds } from '@/services/session';

import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import RootState from './state';

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
    state: {
        activeCustomerId: null,
        customerIds: [],
        customer: null,

        orders: [],

        contracts: [],

        documents: [],
        taskSiteDocuments: {},

        loading: false,
        userLoading: false,

        user: null,
        loggedIn: true,
        users: [],

        taskSites: [],

        categories: [],
        contractTemplates: [],
        equipment: [],

        holidays: [],
    },
    mutations,
    actions,
    getters,
});

/**
 * Init store with some initial data (usually stored directly in the DOM by the backend)
 */
export const initStore = () => {
    store.commit('setActiveCustomerId', getActiveCustomerId());
    store.commit('setCustomerIds', getCustomerIds());

    return store;
};

export default store;
