export interface PortalConfig {
    parameters: {
        datepickerRolloverHour: number;
    };
}

/**
 * Get the config that was injected into the dom by the backend
 */
export function getConfig() {
    return (window as any).PORTAL as PortalConfig;
}

export type ParamKey = 'datepickerRolloverHour';

/**
 * Get a param injected by the backend
 */
export function getParam(key: ParamKey) {
    const val = getConfig().parameters[key];

    if (val) {
        return val;
    }

    return undefined;
}
