














import { defineComponent, computed } from '@vue/composition-api';

interface Props {
    selected: boolean;

    enabled: boolean;

    rootTag: string;

    shape: string;
}

/**
 * Item with a check icon
 */
export default defineComponent<Props>({
    props: {
        selected: {
            type: Boolean,
            required: false,
            default: false,
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true,
        },
        rootTag: {
            type: String,
            required: false,
            default: 'div',
        },
        shape: {
            type: String,
            required: false,
            default: 'square',
        },
    },
    setup(props) {
        const tag = computed(() => props.rootTag);

        const isSelected = computed(() => props.selected);

        const isEnabled = computed(() => props.enabled);

        const itemClasses = computed(() => {
            return {
                'check-item': true,
                'check-item--selected': isSelected.value,
                'check-item--disabled': !isEnabled.value,
            };
        });

        const iconClasses = computed(() => {
            return {
                fa: true,
                'fa-fw': true,
                [`fa-${props.shape}`]: !isSelected.value && isEnabled.value,
                [`fa-check-${props.shape}`]: isSelected.value && isEnabled.value,
                'fa-times': !isEnabled.value,
                'check-item__icon': true,
            };
        });

        return {
            tag,
            isSelected,
            itemClasses,
            iconClasses,
        };
    },
});
