import { format, sub, parseISO, set, add, isWeekend, isSaturday, isSunday } from 'date-fns';
import { de } from 'vuejs-datepicker/dist/locale';
import { Ref, computed } from '@vue/composition-api';
import Holiday from '@/types/Holiday';
import { getParam } from './params';

/**
 * The hour when a day 'rolls over' to the next
 *
 * Used in the order datepicker to roll over the earliest selectable date for
 * delivery
 */
export const DAY_ROLLOVER_HOUR = 16;

/**
 * Usually used date format
 */
export const dateFormat = 'dd.MM.yyyy';

/**
 * Format a date with our standard date format
 */
export const formatDate = (date: Date) => format(date, dateFormat);

/**
 * Default/base datepicker (vue-datepicker) config
 *
 * Individual datepickers should be based on this
 */
export const defaultDatepickerConfig = {
    format: formatDate,
    language: de,
    mondayFirst: true,
    fullMonthName: true,
    typeable: false,
};

/**
 * Default filter dates for the date range filter
 */
export const defaultFilterDates = {
    from: sub(new Date(), { months: 2 }),
    to: new Date(),
};

/**
 * Holiday-aware datepicker using vue's composition api
 *
 * Returns cellContent (pipe that into the datepicker config) and selectedHoliday, a reactive var
 * containing a Holiday if one is selected
 */
export function useDatepicker(
    selectedDate: Ref<Date> | Readonly<Ref<Date>>,
    holidayOn: (dt: Date) => Holiday | undefined
) {
    function cellContent(day: any) {
        const dt = new Date(day.timestamp);

        const holiday = holidayOn(dt);

        if (holiday && isWeekend(dt)) {
            return `<span class="text-danger holiday" style="opacity: .5">${format(
                dt,
                'd'
            )}</span>`;
        } else if (holiday && !isWeekend(dt)) {
            return `<span class="text-danger holiday">${format(dt, 'd')}</span>`;
        }

        return format(dt, 'd');
    }

    const selectedHoliday = computed(() => holidayOn(selectedDate.value));

    return {
        cellContent,
        selectedHoliday,
    };
}

/**
 * Convert 'raw' json props into objects where necessary
 */
export function materializeHolidays(data: any): Holiday[] {
    return data.map((i: any) => {
        return {
            id: i.id,
            date: parseISO(i.date),
            title: i.title,
        } as Holiday;
    });
}

/**
 * Find the earliest delivery date from now
 */
export function getEarliestDeliveryDate() {
    // 'now' in mobi headquarters
    const now = new Date();

    // Now with time components zeroed out
    const nowDate = set(now, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    // Generally allow ordering for the next day
    let earliestDate = add(nowDate, { days: 1 });

    // Unless it's already late
    if (now.getHours() >= (getParam('datepickerRolloverHour') || DAY_ROLLOVER_HOUR)) {
        earliestDate = add(nowDate, { days: 2 });
    }

    if (isSaturday(earliestDate)) {
        earliestDate = add(earliestDate, { days: 2 });
    } else if (isSunday(earliestDate)) {
        earliestDate = add(earliestDate, { days: 1 });
    }

    return earliestDate;
}
