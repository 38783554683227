import Order from '@/types/Order';
import { statusSortIndex } from '@/services/status';
import { compareTaskSites } from '@/services/location';

export function sortOrdersByStatus(a: Order, b: Order) {
    const statusSort = statusSortIndex(a.status.code) - statusSortIndex(b.status.code);

    if (statusSort !== 0) {
        return statusSort;
    }

    if (!a.taskSite || !b.taskSite) {
        return statusSort;
    }

    return compareTaskSites(a.taskSite, b.taskSite);
}

export function sortOrdersByLocation(a: Order, b: Order) {
    if (a.taskSite && b.taskSite) {
        const locationSort = compareTaskSites(a.taskSite, b.taskSite);

        if (locationSort !== 0) {
            return locationSort;
        }
    }

    // Location is the same, sort by status
    const statusSort = statusSortIndex(a.status.code) - statusSortIndex(b.status.code);

    return statusSort;
}

/**
 * Convert raw json props into the correct types
 */
export function materializeOrders(data: any): Order[] {
    return data.map((i: any) => {
        return {
            ...i,
            quantity: Number(i.quantity),
        } as Order;
    });
}
