import { Claim } from '@/types/User';

/**
 * Claim types (predefined by ASP.NET or custom)
 */
export enum ClaimTypes {
    NameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    PortalCustomer = 'PortalCustomer',
    PortalOrder = 'PortalOrder',
    PortalDocuments = 'PortalDocuments',
    PortalUserManagement = 'PortalUserManagement',
}

/**
 * Claim type labels for the ui
 */
export const CLAIM_TYPE_LABELS = {
    [ClaimTypes.PortalOrder]: 'Aufträge anlegen',
    [ClaimTypes.PortalDocuments]: 'Dokumente einsehen',
    [ClaimTypes.PortalUserManagement]: 'Benutzerverwaltung',
};

/**
 * Specific claim types for the portal
 */
export const portalClaimTypes = [
    ClaimTypes.PortalCustomer,
    ClaimTypes.PortalOrder,
    ClaimTypes.PortalDocuments,
    ClaimTypes.PortalUserManagement,
];

/**
 * Claim types that can be changed directly in user management
 */
export const mutablePortalClaimTypes = [
    ClaimTypes.PortalOrder,
    ClaimTypes.PortalDocuments,
    ClaimTypes.PortalUserManagement,
];

/**
 * Map a list of claim types into an object of [claim type] => false
 */
export function mapClaimTypes(types: string[]) {
    const claims: { [key: string]: boolean } = {};

    for (const type of types) {
        claims[type] = false;
    }

    return claims;
}

/**
 * Map a list of claim types and a list of claims into an object of [claim type] => true/false
 */
export function mapClaimTypesFor(types: string[], currentClaims: Claim[]) {
    const claims: { [key: string]: boolean } = {};

    for (const type of types) {
        claims[type] = currentClaims.find((c) => c.type === type) ? true : false;
    }

    return claims;
}
