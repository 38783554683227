






















import { defineComponent } from '@vue/composition-api';
import { MountingPortal } from 'portal-vue';

import CustomerSelect from '@/components/shared/CustomerSelect.vue';
import LoggedOutError from '@/components/shared/LoggedOutError.vue';

/**
 * Page base component shared between all "applets"
 *
 * Mounts two components into elements outside of the actual vue tree
 * - Customer number select (top right in the menu)
 * - Container with a "logged out" message
 */
export default defineComponent({
    components: {
        CustomerSelect,
        MountingPortal,
        LoggedOutError,
    },
});
