















import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { DialogOpts } from '@/services/dialogs';
import DialogWrapper from '@/components/dialogs/DialogWrapper.vue';

const makeId = ((index = 0) => () => (index++).toString())();

interface Dialog {
    id: string;
    opts: DialogOpts;
    resolve: (value?: any) => void;
    promise: Promise<any>;
}

export default defineComponent({
    components: {
        DialogWrapper,
    },
    setup(props, { root }) {
        const dialogs = ref<Dialog[]>([]);

        /**
         * Push new dialog onto dialog 'stack'
         */
        function push(opts: DialogOpts) {
            let resolver: any = null;

            const p = new Promise<any>((resolve) => {
                resolver = resolve;
            });

            const d: Dialog = {
                id: makeId(),
                opts,
                resolve: resolver,
                promise: p,
            };

            dialogs.value.push(d);

            return p;
        }

        /**
         * A dialog was closed, remove it from stack and resolve
         */
        function onClose(dialog: Dialog, data: any) {
            const index = dialogs.value.indexOf(dialog);

            if (index !== -1) {
                dialogs.value.splice(index, 1);

                dialog.resolve(data);
            }
        }

        return {
            dialogs,
            push,
            onClose,
        };
    },
});
