



















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        text: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: 'info-circle',
        },
        // 0 - 5
        margin: {
            type: Number,
            default: 1,
        },
        // 'before' or 'after'
        location: {
            type: String,
            default: 'before',
        },
    },
});
