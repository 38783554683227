import Vue, { VueConstructor } from 'vue';
import DialogContainer from '@/components/dialogs/DialogContainer.vue';
import InfoDialog from '@/components/dialogs/InfoDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

export interface DialogOpts {
    component: any;

    props: any;
}

/**
 * Props for the info dialog
 */
export interface InfoDialogProps {
    title?: string;

    text: string;

    ok?: string;
}

/**
 * Props for the confirm dialog
 */
export interface ConfirmDialogProps extends InfoDialogProps {
    cancel?: string;

    destructive?: boolean;
}

/**
 * Runtime dialog 'manager' object
 */
const dialogRuntime: {
    containerComponent?: {
        push: (opts: { component: object; props: object }) => Promise<any>;
    };
    root?: Vue;
} = {};

/**
 * Definition of a component
 *
 * Usually a vue component definition object would be passed in, but for convenience the info and
 * confirm dialogs are special-cased
 */
type ComponentDef = 'info' | 'confirm' | object;

/**
 * Props that could be passed to a dialog component
 *
 * Again, info and confirm dialogs are special-cased for better type-inference
 */
type DialogComponentProps = InfoDialogProps | ConfirmDialogProps | any;

/**
 * Overload for the info dialog
 */
export function showDialog(component: 'info', props: InfoDialogProps): Promise<true>;

/**
 * Overload for the confirm dialog
 */
export function showDialog(component: 'confirm', props: ConfirmDialogProps): Promise<boolean>;

/**
 * Generic overload
 */
export function showDialog<T = any>(component: object, props?: any): Promise<T>;

/**
 * Actual impl
 */
export function showDialog<T = any>(
    component: ComponentDef,
    props: DialogComponentProps = {}
): Promise<T> {
    let comp: object | null = null;

    // Special cased dialog components
    if (component === 'info') {
        comp = InfoDialog;
    } else if (component === 'confirm') {
        comp = ConfirmDialog;
    } else {
        // Generic
        comp = component;
    }

    if (!dialogRuntime.containerComponent) {
        throw new Error('Dialogs not initialized');
    }

    return dialogRuntime.containerComponent.push({
        component: comp,
        props,
    });
}

/**
 * Set up dom and containers
 */
export function initDialogs(root: Vue) {
    (root as any).$pushDialog = showDialog;

    // Create new el in the body tag
    const containerEl = document.createElement('div');

    document.body.appendChild(containerEl);

    // Inject a separate vue component into it
    const container = new Vue({
        parent: root,
        render: (h) => h(DialogContainer),
    }).$mount(containerEl);

    dialogRuntime.root = root;

    dialogRuntime.containerComponent = container.$children[0] as any;
}
