import Vue, { VueConstructor } from 'vue';

import { euroFilter } from './currency';
import { dateFilter } from './dates';

export function registerFilters(v: VueConstructor<Vue>): void {
    v.filter('euro', euroFilter);

    v.filter('date', dateFilter);
}
