import { parseISO, format } from 'date-fns';

/**
 * Status definition
 */
interface StatusDef {
    sortIndex: number;

    /**
     * Simple label used in the legend and other contexts _without_ an actual (order) status
     */
    simpleLabel: string;

    /**
     * Fn that builds a label from an (optional) status value (like a date). See the RichStatus of
     * the Order type
     */
    buildLabel: (value: any) => string;

    /**
     * Help/explanatory text
     */
    desc?: string;
}

/**
 * All statuses
 */
export const statusDefinitions: { [key: string]: StatusDef } = {
    NEW: {
        sortIndex: 1,
        simpleLabel: 'Neu',
        buildLabel: (value) => 'Neu',
        desc:
            'Der Auftrag wurde übermittelt und noch nicht bearbeitet. Der Auftrag kann jederzeit über den Reiter „Löschen“ gelöscht bzw. storniert werden',
    },
    ACCEPTED: {
        sortIndex: 2,
        simpleLabel: 'In Bearbeitung',
        buildLabel: (value) => 'In Bearbeitung',
        desc: 'Der Auftrag befindet sich in Bearbeitung und wird für die Disposition vorbereitet',
    },
    PLANNED: {
        sortIndex: 3,
        simpleLabel: 'Disponiert',
        buildLabel: (value) => {
            if (!value) {
                return `Disponiert`;
            }

            const date = parseISO(value);

            return `Disponiert für ${format(date, 'dd.MM.')}`;
        },
        desc:
            'Die Lieferung der mobilen Toilette wurde eingeplant und erfolgt zum genannten Termin',
    },
    ACTIVE: {
        sortIndex: 4,
        simpleLabel: 'Aktiv',
        buildLabel: (value) => 'Aktiv',
        desc: 'Die mobile Toilette steht vor Ort und wird turnusmäßig gereinigt',
    },
    RETRIEVAL: {
        sortIndex: 5,
        simpleLabel: 'Abholung',
        buildLabel: (value) => 'Abholung läuft',
        desc:
            'Die Abholung der mobilen Toilette wurde eingeplant und erfolgt mit der nächsten Regeltour',
    },
    FINISHED: {
        sortIndex: 6,
        simpleLabel: 'Beendet',
        buildLabel: (value) => 'Beendet',
        desc: 'Dieser Auftrag ist abgeschlossen und wird nach 2 Monaten aus der Übersicht entfernt',
    },
};

export const statusSortIndex = (status: string) => statusDefinitions[status].sortIndex;

/**
 * Build css classes
 *
 * E.g. someprefix--new, someprefix--planned, someprefix--active, ...
 */
export const buildStatusClasses = (prefix: string, value: string) => {
    const classes: { [key: string]: boolean } = {};

    for (const status of Object.keys(statusDefinitions)) {
        classes[`${prefix}--${status.toLowerCase()}`] = status === value;
    }

    return classes;
};

export const statusLabel = (status: string, statusValue: any) =>
    statusDefinitions[status].buildLabel(statusValue);
