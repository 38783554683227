






















import { defineComponent } from '@vue/composition-api';
import { InfoDialogProps } from '@/services/dialogs';

export default defineComponent<InfoDialogProps>({
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            required: true,
        },
        ok: {
            type: String,
            default: 'OK',
        },
    },
});
