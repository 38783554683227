import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import VTooltip from 'v-tooltip';
import 'whatwg-fetch';
import 'core-js/stable/regexp';

import { initStore } from '@/store/store';
import { registerFilters } from '@/filters/filters';
import { initDialogs } from '@/services/dialogs';

/**
 * Sets up a vue page app
 */
export function makeApp(mainComponent: any) {
    registerFilters(Vue);

    Vue.use(VueCompositionApi);
    Vue.use(VTooltip);

    Vue.config.productionTip = false;

    const root = new Vue({
        store: initStore(),
        render: (h) => h(mainComponent),
    });

    initDialogs(root);

    root.$mount('#app');
}
