import { MutationTree } from 'vuex';

import RootState from './state';
import Order from '@/types/Order';
import Document from '@/types/Document';
import User from '@/types/User';
import TaskSite from '@/types/TaskSite';
import Category from '@/types/Category';
import ContractTemplate from '@/types/ContractTemplate';
import Equipment from '@/types/Equipment';
import Customer from '@/types/Customer';
import Holiday from '@/types/Holiday';
import Contract from '@/types/Contract';

/**
 * These are the sync mutations (setters) for the store
 */
export const mutations: MutationTree<RootState> = {
    setActiveCustomerId(state, id: string) {
        state.activeCustomerId = id;
    },

    clearCustomerData(state) {
        state.orders = [];
        state.documents = [];
        state.taskSiteDocuments = {};
    },

    setCustomerIds(state, ids: string[]) {
        state.customerIds = ids;
    },

    setCustomer(state, customer: Customer) {
        state.customer = customer;
    },

    setLoading(state, loading: boolean) {
        state.loading = loading;
    },

    setUserLoading(state, loading: boolean) {
        state.userLoading = loading;
    },

    setOrders(state, orders: Order[]) {
        state.orders = orders;
    },

    setContracts(state, contracts: Contract[]) {
        state.contracts = contracts;
    },

    setDocuments(state, documents: Document[]) {
        state.documents = documents;
    },

    setTaskSiteDocuments(
        state,
        { taskSiteId, documents }: { taskSiteId: string; documents: Document[] }
    ) {
        state.taskSiteDocuments = { ...state.taskSiteDocuments, [taskSiteId]: documents };
    },

    setUser(state, user: User) {
        state.user = user;
    },

    setLoggedIn(state, loggedIn: boolean) {
        state.loggedIn = loggedIn;
    },

    setTaskSites(state, taskSites: TaskSite[]) {
        state.taskSites = taskSites;
    },

    setCategories(state, categories: Category[]) {
        state.categories = categories;
    },

    setContractTemplates(state, templates: ContractTemplate[]) {
        state.contractTemplates = templates;
    },

    setEquipment(state, equipment: Equipment[]) {
        state.equipment = equipment;
    },

    setUsers(state, users: User[]) {
        state.users = users;
    },

    setHolidays(state, holidays: Holiday[]) {
        state.holidays = holidays;
    },
};
