/// <reference types="googlemaps"/>

import LocationModel from '@/types/LocationModel';
import TaskSite from '@/types/TaskSite';

const STREET_NUMBER_COMPONENT_TYPE = 'street_number';
const STREET_COMPONENT_TYPE = 'route';
const CITY_COMPONENT_TYPE = 'locality';
const ZIP_COMPONENT_TYPE = 'postal_code';

/**
 * Try to access the browser's native geolocation
 */
export function retrieveCurrentPosition(okFn: PositionCallback) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(okFn);
    }
}

/**
 * Reverse geocode coords into an address object
 */
export function reverseGeocode(pos: {
    lat: number;
    lng: number;
}): Promise<google.maps.GeocoderResult[]> {
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode({ location: pos }, (results, status) => {
            resolve(results || []);
        });
    });
}

/**
 * Check if the given location returns a valid address object
 */
export function isValidLocation(loc: LocationModel) {
    const geocoder = new google.maps.Geocoder();

    const locationString = `${loc.street} ${loc.streetNumber}, ${loc.zip} ${loc.city}`;

    const promise = new Promise((resolve, reject) => {
        geocoder.geocode(
            {
                address: locationString,
            },
            (results, status) => {
                resolve(
                    status === google.maps.GeocoderStatus.OK &&
                        hasRequiredComponents(results[0].address_components)
                );
            }
        );
    });

    return promise;
}

/**
 * Check if the given components contain all required components
 */
export function hasRequiredComponents(components: google.maps.GeocoderAddressComponent[]) {
    const hasStreet = components.some((c) => c.types.includes(STREET_COMPONENT_TYPE));
    const hasCity = components.some((c) => c.types.includes(CITY_COMPONENT_TYPE));
    const hasZip = components.some((c) => c.types.includes(ZIP_COMPONENT_TYPE));

    return hasStreet && hasCity && hasZip;
}

/**
 * Check if location model's required fields are filled
 */
export function locationHasRequiredComponents(location: LocationModel) {
    return (
        location.city !== '' &&
        location.street !== '' &&
        location.street !== 'Unnamed Road' &&
        location.zip !== ''
    );
}

/**
 * Update location model fields from google address components
 */
export function updateLocation(
    loc: LocationModel,
    latLng: google.maps.LatLngLiteral | google.maps.LatLng,
    components: google.maps.GeocoderAddressComponent[]
) {
    const zip = components.find((c) => c.types.includes(ZIP_COMPONENT_TYPE));

    loc.zip = zip ? zip.long_name : '';

    const city = components.find((c) => c.types.includes(CITY_COMPONENT_TYPE));

    loc.city = city ? city.long_name : '';

    const street = components.find((c) => c.types.includes(STREET_COMPONENT_TYPE));

    loc.street = street ? (street.long_name === 'Unnamed Road' ? '' : street.long_name) : '';

    const streetNumber = components.find((c) => c.types.includes(STREET_NUMBER_COMPONENT_TYPE));

    loc.streetNumber = streetNumber ? streetNumber.long_name : '';

    if (latLng instanceof google.maps.LatLng) {
        latLng = latLng.toJSON();
    }

    loc.lat = latLng.lat;
    loc.lon = latLng.lng;
}

/**
 * Compare task site addresses
 */
export function compareTaskSites(a: TaskSite, b: TaskSite) {
    if (a.postcode && b.postcode) {
        const zipSort = a.postcode.localeCompare(b.postcode);

        if (zipSort !== 0) {
            return zipSort;
        }
    }

    if (a.address && b.address) {
        return a.address.localeCompare(b.address);
    }

    return 0;
}

/**
 * Generate a location string for a task site
 */
export function taskSiteLocationString(t?: TaskSite) {
    if (!t || (!t.address && !t.city && !t.postcode)) {
        return undefined;
    }

    return `${t.postcode} ${t.city}, ${t.address}`;
}
